
  import { defineComponent, toRef } from 'vue';
  export default defineComponent({
    props: ['accessDenied'],
    setup(props) {
      const blockedAccess = toRef(props, 'accessDenied');
      return {
        blockedAccess,
      };
    },
  });
