
  import { defineComponent } from 'vue';
  export default defineComponent({
    emit: ['update:digit'],
    setup(props, { emit }) {
      const buttons = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'];
      const keyStyle = { '--key-color': '#f2f2f5' };
      const emitDigit = (n: string) => {
        emit('update:digit', n);
      };
      return {
        emitDigit,
        buttons,
        keyStyle,
      };
    },
  });
