
  import { defineComponent } from 'vue';

  export default defineComponent({
    emit: ['update:callBtn'],
    setup(props, { emit }) {
      const redial = () => {
        emit('update:callBtn');
      };
      return {
        redial,
      };
    },
  });
