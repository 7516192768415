
  import { defineComponent, ref, toRef } from 'vue';
  export default defineComponent({
    props: ['call'],
    emits: ['update:isMuted'],
    setup(props, { emit }) {
      const currentCall = toRef(props, 'call');
      const muted = ref<boolean>(false);
      const micImage = ref<string>('/icons.svg#unmuted');
      const toggleMic = () => {
        muted.value = !muted.value;
        if (muted.value) {
          micImage.value = '/icons.svg#muted';
          emit('update:isMuted', 'Unmute');
          return currentCall.value?.muteMicrophone();
        }
        micImage.value = '/icons.svg#unmuted';
        emit('update:isMuted', 'Mute');
        currentCall.value?.unmuteMicrophone();
      };
      return {
        muted,
        toggleMic,
        micImage,
      };
    },
  });
