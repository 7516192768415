import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17cbffb2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "mic-key",
  width: '44',
  height: '44'
}
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "microphone",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleMic && _ctx.toggleMic(...args))),
    tabindex: "0"
  }, [
    (_openBlock(), _createElementBlock("svg", _hoisted_1, [
      _createElementVNode("use", { href: _ctx.micImage }, null, 8, _hoisted_2)
    ]))
  ]))
}