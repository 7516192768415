import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fa091954"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "connection-rate" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock("svg", {
      class: "indicator",
      style: _normalizeStyle(_ctx.levelStyle),
      width: "44",
      height: "44"
    }, [
      _createElementVNode("use", { href: '/icons.svg#indicator' }, null, 8, _hoisted_2)
    ], 4))
  ]))
}