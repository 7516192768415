
  import { defineComponent } from 'vue';
  import { Button } from '@voximplant/spaceui';
  import Decline from '@/components/Decline.vue';

  export default defineComponent({
    components: { Decline, Button },
    emit: ['update:cancelBtn'],
    setup(props, { emit }) {
      const hangup = () => {
        emit('update:cancelBtn');
      };
      return {
        hangup,
      };
    },
  });
