import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-938c1098"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "decline-key",
  width: "44",
  height: "44"
}
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "decline",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.disconnect && _ctx.disconnect(...args))),
    tabindex: "0"
  }, [
    (_openBlock(), _createElementBlock("svg", _hoisted_1, [
      _createElementVNode("use", {
        href: '/icons.svg#decline',
        width: "44",
        height: "44"
      }, null, 8, _hoisted_2)
    ]))
  ]))
}