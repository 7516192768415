import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62ce1621"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "digits-wrapper" }
const _hoisted_2 = { class: "dtmf-keyboard" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  class: "key-btn",
  width: '44',
  height: '44'
}
const _hoisted_5 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (n) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "digit",
          onClick: ($event: any) => (_ctx.emitDigit(n)),
          tabindex: "0"
        }, [
          (_openBlock(), _createElementBlock("svg", _hoisted_4, [
            _createElementVNode("use", {
              href: `/icons.svg#button-${n}`
            }, null, 8, _hoisted_5)
          ]))
        ], 8, _hoisted_3))
      }), 256))
    ])
  ]))
}