
  import { DropdownOptionProps, Select, Button } from '@voximplant/spaceui';
  import { defineComponent, onMounted, reactive, ref, toRef } from 'vue';
  import * as VoxImplant from 'voximplant-websdk';
  import { AudioParams } from 'voximplant-websdk/Hardware/src';
  import { AudioSourceInfo } from 'voximplant-websdk/Structures';
  import Volume from '@/components/Volume.vue';
  export default defineComponent({
    components: { Volume, Select, Button },
    props: ['call'],
    emit: ['update:closeSettings'],
    setup(props, { emit }) {
      const call = toRef(props, 'call');
      const item: DropdownOptionProps = { label: '', value: '' };
      const mics = reactive({
        list: [item],
      });
      const active = ref<DropdownOptionProps>({ label: '', value: '' });
      const inputId = ref<string>('');
      onMounted(async () => {
        const devices: AudioSourceInfo[] =
          await VoxImplant.Hardware.AudioDeviceManager.get().getInputDevices();
        const selectMics = devices.map((mic: AudioSourceInfo) => ({
          label: mic.name,
          value: mic.id,
        }));
        mics.list = selectMics;
        active.value =
          mics.list.find(
            (item) =>
              item.value ===
              VoxImplant.Hardware.AudioDeviceManager.get().getDefaultAudioSettings().inputId
          ) || mics.list[0];
      });
      const changeMicrophone = (input: HTMLInputElement) => {
        inputId.value = input.value;
      };
      const setNewMicrophone = () => {
        const audioParams: AudioParams = { inputId: inputId.value };
        if (call.value) {
          VoxImplant.Hardware.AudioDeviceManager.get().setCallAudioSettings(
            call.value,
            audioParams
          );
        }
        VoxImplant.Hardware.AudioDeviceManager.get().setDefaultAudioSettings(audioParams);
        closeSettings();
      };
      const closeSettings = () => {
        emit('update:closeSettings');
      };
      const detectAudioWorklet = () => {
        if (window['OfflineAudioContext']) {
          let context = new window['OfflineAudioContext'](1, 1, 44100);
          const result =
            context.audioWorklet && typeof context.audioWorklet.addModule === 'function';
          return result;
        }
        return false;
      };
      const audioWorkletDetected = detectAudioWorklet();
      return {
        mics,
        active,
        changeMicrophone,
        setNewMicrophone,
        closeSettings,
        audioWorkletDetected,
      };
    },
  });
