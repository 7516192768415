import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b074538"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "volume" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock("svg", {
      style: _normalizeStyle(_ctx.volumeLevelColor),
      width: "248",
      height: "4"
    }, [
      _createElementVNode("use", {
        href: '/icons.svg#volume-indicator',
        width: "248",
        height: "4"
      }, null, 8, _hoisted_2)
    ], 4))
  ]))
}