
  import { defineComponent, ref } from 'vue';
  import Connection from '@/components/Connection.vue';
  import { Button, Hint } from '@voximplant/spaceui';
  import * as VoxImplant from 'voximplant-websdk';
  import { Call } from 'voximplant-websdk/Call/Call';
  import { CallState } from '@/enums/CallState';
  import RedialCall from '@/components/RedialCall.vue';
  import MicPermission from '@/components/MicPermission.vue';
  import Settings from '@/components/Settings.vue';
  import DtmfKeyboard from '@/components/DtmfKeyboard.vue';
  import CheckingMic from '@/components/CheckingMic.vue';
  import Timer from '@/components/Timer.vue';
  import ConnectionRate from '@/components/ConnectionRate.vue';
  import Microphone from '@/components/Microphone.vue';
  import Decline from '@/components/Decline.vue';
  import { getParameters } from '@/helpers/get-parameters';

  export default defineComponent({
    components: {
      Decline,
      Microphone,
      ConnectionRate,
      Timer,
      CheckingMic,
      DtmfKeyboard,
      Settings,
      MicPermission,
      RedialCall,
      Connection,
      Button,
      Hint,
    },
    setup() {
      const callState = ref<string>('');
      const accessDenied = ref<boolean>(false);
      const isMicAccessGranted = ref<boolean>(false);
      const sdk = VoxImplant.getInstance();
      const parameters = getParameters();
      sdk.on(VoxImplant.Events.MicAccessResult, (e) => {
        if (e.result === true) {
          isMicAccessGranted.value = true;
        } else {
          accessDenied.value = true;
        }
      });
      const call = ref<Call | null>(null);
      sdk
        .init({
          micRequired: true,
          showDebugInfo: true,
          progressTone: true,
          progressToneCountry: 'US',
        })
        .then(() => sdk.connect(false))
        .then(() => sdk.login(process.env.VUE_APP_USER, process.env.VUE_APP_PASSWORD))
        .then(() => {
          createCall();
        });
      const disconnect = () => {
        call.value?.hangup();
      };
      const createCall = () => {
        call.value = sdk.call({
          number: process.env.VUE_APP_NUMBER,
          video: { sendVideo: false, receiveVideo: false },
          extraHeaders: parameters,
        });
        callState.value = CallState.CONNECTING;
        call.value.on(VoxImplant.CallEvents.Connected, () => {
          callState.value = CallState.CONNECTED;
        });
        call.value.on(VoxImplant.CallEvents.Disconnected, () => {
          callState.value = CallState.DISCONNECTED;
        });
        call.value.on(VoxImplant.CallEvents.Failed, () => {
          callState.value = CallState.DISCONNECTED;
        });
      };
      const showSettings = ref<boolean>(false);
      const checkingOpened = ref<boolean>(false);
      const sendDigit = (digit: string) => {
        call.value?.sendTone(digit);
      };
      const startTestMic = () => {
        checkingOpened.value = true;
        call.value?.hangup();
      };
      const micHint = ref<string>('Mute');
      const changeMicHint = (value: string) => {
        micHint.value = value;
      };
      return {
        callState,
        CallState,
        createCall,
        disconnect,
        isMicAccessGranted,
        accessDenied,
        showSettings,
        checkingOpened,
        sendDigit,
        sdk,
        call,
        startTestMic,
        changeMicHint,
        micHint,
      };
    },
  });
