
  import { defineComponent } from 'vue';
  import { callButton } from '../../static/button';
  export default defineComponent({
    setup() {
      return {
        callButton,
      };
    },
  });
