
  import { defineComponent } from 'vue';
  export default defineComponent({
    setup() {
      const declineImage = '/icons.svg#decline';
      return {
        declineImage,
      };
    },
  });
