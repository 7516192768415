
  import { defineComponent, ref } from 'vue';
  import { Button } from '@voximplant/spaceui';
  import * as VoxImplant from 'voximplant-websdk';
  import { Call } from 'voximplant-websdk/Call/Call';
  import Timer from '@/components/Timer.vue';
  import { CallState } from '@/enums/CallState';
  import { EventHandlers } from 'voximplant-websdk/EventHandlers';

  export default defineComponent({
    components: { Timer, Button },
    emit: ['update:checking'],
    props: ['sdk'],
    setup(props, { emit }) {
      const message = ref<string>('Connection with service...');
      const timerState = ref<string>('');
      const btnName = ref<string>('Cancel');
      const record = ref<string>('');
      let totalPacketLost: number | undefined = 0;
      let call: Call | null = null;
      const createTestCall = () => {
        const sdk = props.sdk;
        call = sdk?.call({ number: process.env.VUE_APP_TEST_NUMBER });
        call?.on(VoxImplant.CallEvents.Connected, () => {
          message.value =
            'Please record your message, afterwards your message will be played back to you.';
        });
        call?.on(VoxImplant.CallEvents.MessageReceived, (e: EventHandlers.MessageReceived) => {
          if (e.text === 'record') {
            timerState.value = CallState.CONNECTED;
          } else {
            message.value = `All works! Total packet lost is ${totalPacketLost}%.`;
            btnName.value = 'Close';
            timerState.value = CallState.DISCONNECTED;
            record.value = e.text;
          }
        });
        call?.on(VoxImplant.CallEvents.CallStatsReceived, (e: EventHandlers.CallStatsReceived) => {
          totalPacketLost = e.stats.totalPacketsLost;
        });
      };
      createTestCall();
      const stopChecking = () => {
        emit('update:checking');
        call?.hangup();
      };
      return {
        message,
        createTestCall,
        stopChecking,
        btnName,
        timerState,
        CallState,
        record,
      };
    },
  });
